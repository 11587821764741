import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { PresentationComponent } from './presentation/presentation.component';
import { InfoComponent } from './info/info.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    PresentationComponent,
    InfoComponent,
    HeaderComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,  AppRoutingModule, 
    RouterModule 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
